#ironCondorCalculator {
    color: #fff;
    max-width: 600px;
    width: 100%;

    h1 {
        margin: 10px 0px 5px;
    }

    div {
        min-height: 25px;

        input {
            background-color: rgba(0, 0, 0, 0.2);
            border: none;
            border-bottom: #fff solid 1px;
            border-radius: 0px;
            color: #fff;
            font-size: 14px;
            margin: 3px 10px;
            padding: 3px 5px;
            width: 60px;

            /* Firefox */
            -moz-appearance: textfield;

            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &:focus {
                outline: none;
                border-bottom-color: #20a2e8;
            }
        }

        span.bold {
            font-weight: bold;
            text-decoration: underline;
        }
    }
}