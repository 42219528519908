.jsonViewTool {
    display: grid;
    // grid-template-rows: 30px 1fr;
    height: 100%;
    position: relative;
    width: 100%;

    .jsonHeader {
        // margin: 10px 15px;
        margin: 0px;
    }

    .jsonViewContainer {
        background-color: #fff;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 26px 1fr;
        padding: 15px;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        .tabBar {
            display: grid;
            grid-column: span 2;

            .tabButton {
                border: solid 1px #ddd;
                cursor: pointer;
                display: inline-block;
                font-size: 14px;
                height: 16px;
                padding: 4px 5px;
                position: relative;
                text-align: center;

                &:not(:first-child) {
                    border-left: none;
                }

                &.selected {
                    background-color: #ddd;
                }

                &.addButton {
                    line-height: 15px;
                }

                .closeButton {
                    display: none;
                    font-family: sans-serif;
                    position: absolute;
                    right: 6px;
                    top: 4px;

                    &:hover {
                        color: #f00;
                    }
                }

                &:hover .closeButton {
                    display: block;
                }
            }
        }

        .jsonTextContainer, .jsonOutputContainer {
            display: grid;
            grid-template-rows: 1fr 40px;
        }

        .jsonTextContainer {

            .jsonText {
                font-family: monospace;
                font-size: 12px;
                resize: none;
            }

            .prettifyButtonsContainer {
                display: grid;
                grid-gap: 12px;
                grid-template-columns: 1fr 1fr;

                .myInput {
                    margin: 12px 0px 0px;
                }
            }
        }

        .jsonOutputContainer {
            overflow: auto;

            .jsonOutput {
                overflow: auto;
            }
        }
    }
}